<template>

    <div>
        <CForm ref="form" class="needs-validation">
            <CRow>

                <CCol col="6" v-if="data.contentMain && data.contentMain.contentTypeFolder !== ''">
                    <CInput
                        label="Yan Başlık (Küçük)"
                        type="text"
                        horizontal
                        v-model="data.text"
                    />
                </CCol>

                <CCol >
                    <CInput
                        :label=" (data.contentMain && data.contentMain.contentTypeFolder !== '') ? 'Başlık (Büyük)' : 'İçerik' "
                        type="text"
                        horizontal
                        v-model="data.title"
                    />
                </CCol>

            </CRow>

            <CRow>

                <CCol col="6">
                    <CSelect
                        label="Dil"
                        type="text"
                        placeholder="Seçiniz"
                        :options="langs"
                        :value.sync="data.langId"
                        horizontal
                        required
                    />
                </CCol>

                <CCol col="6">

                    <CRow>

                        <CCol v-if="data.contentMain && data.contentMain.contentTypeFolder !== ''" style="display: flex; align-items: center; margin-bottom: .5rem;">
                            <span style="margin-right: .5rem;">Video </span>
                            <CSwitch
                                class="mr-1"
                                size="lg"
                                color="primary"
                                :checked.sync="data.videoFlag"
                            />
                        </CCol>

                        <CCol style="display: flex; align-items: center; margin-bottom: .5rem;">
                            <span style="margin-right: .5rem;">Mobil </span>
                            <CSwitch
                                class="mr-1"
                                size="lg"
                                color="primary"
                                :checked.sync="data.mobileFlag"
                            />
                        </CCol>

                    </CRow>

                </CCol>

            </CRow>

            <CRow>
            
                <CCol col="12">
                    <CInput
                        label="Yönlendireilecek URL"
                        placeholder="/tisort?priority=1668,1669,1670"
                        type="text"
                        v-model="data.targetURL"
                        horizontal
                        :required="(data.contentMain && data.contentMain.contentTypeFolder !== '')"
                    />
                </CCol>
            </CRow>

            <CRow>
                <CCol></CCol>
                <CCol v-if="data.id" >
                    <CButton color="danger" class="w-100"  @click="cancelEdit"> Düzenlemeyi İptal Et </CButton>
                </CCol>
                <CCol> 
                    <CButton color="success" class="w-100" @click="modalHandle('save')"> {{ data.id ? 'Düzenlemeyi Kaydet' : 'Kaydet'}} </CButton>

                </CCol>
                <CCol></CCol>

            </CRow>

    </CForm>

    <CDataTable
        :items="contents"
        :fields="fields"
        :items-per-page="10"
        hover
        pagination
        :loading="loading"
        clickable-rows
        @row-clicked="rowClicked"
        @row-double-clicked="editRecord(lastItem)"
        v-on:refresh="getContents"
        class="modalTable"
        >
            <template #show_details="{item}">
                <td class="py-2">
                    <CDropdown
                        toggler-text="İşlemler"
                        class="m-2"
                        color="info"
                        size="sm"
                    >
                        <CDropdownItem @click="editRecord(item)">
                            Düzenle
                        </CDropdownItem>
                        <CDropdownItem v-if="data.contentMain.contentTypeFolder !== ''" @click="openModalEvent('images','contentImageForm', {content: item}, 'İçerik Resim Ekranı ('+ item.title+')')">
                            İçerik Ekle (Görsel / Video)
                        </CDropdownItem>
                        <CDropdownItem @click="openModalEvent('delete','confirm', item, 'İçerik Silme Ekranı', item.title+' ( '+ item.text+' )  içeriği silmek üzeresiniz bunu yapmak istediğinizden emin misiniz?')">
                            Kaydı Sil
                        </CDropdownItem>
                    </CDropdown>
                </td>
            </template>

            <template #videoFlag="{item}">
                <td>
                    <CIcon v-if="item.videoFlag" class="green" name="cil-check-alt"/>
                    <CIcon v-else name="cil-x" class="red"/>
                </td>
            </template>

            <template #mobileFlag="{item}">
                <td>
                    <CIcon v-if="item.mobileFlag" class="green" name="cil-check-alt"/>
                    <CIcon v-else name="cil-x" class="red"/>
                </td>
            </template>

            <template #targetURL="{item}">
                <td>
                    <a :href="webDomain + item.targetURL" target="_blank"> {{item.targetURL}} </a>
                </td>
            </template>
            

            <template #text="{item}">
                <td>
                    <p v-html="item.text" style="width: 70px;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;" v-c-tooltip.mouseenter="{content: item.text}"/>
                </td>
            </template>

            <template #lang="{item}">
                <td>
                    {{ item.lang.name }}
                </td>
            </template>
        
        </CDataTable>

        <ConfirmModal
            v-if="form == 'confirm'" 
            :show="openModal" 
            :title="modalTitle" 
            :openModalEvent="openModalEvent"
            :confirm="removeRecord"
            :desc="modalDesc"
            :data="modalProps"
            :actionType="actionTypeModal"
            :loading="loading"
        />

        <FormModal 
            v-else
            :show="openModal" 
            :title="modalTitle" 
            :openModalEvent="openModalEvent"
            :operationEvent="crud"
            :actionType="actionTypeModal"
            :module="module"
            :form="form"
            :data="modalProps"
            :noFooter="true"
        />
</div>

</template>

<script>
    import ConfirmModal from '../../components/confirmModal.vue'
    import FormModal from '../../components/formModal'

    export default{
        name: "contentForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
            modalHandle: Function,
        },
        components: { ConfirmModal, FormModal },
        created: function(){
            if(this.params.contentMain && this.params.contentMain.contentTypeFolder == ""){
                    this.fields = [
                        {key: 'show_details', label: 'İşlemler'},
                        {key: 'lang', label: 'Dil'},
                        {key: 'mobileFlag', label: 'Mobil'},
                        {key: 'title', label: 'İçerik'},
                        {key: 'targetURL', label: 'İçerik Sayfası'}
                    ];
                } else {
                    this.fields = [
                        {key: 'show_details', label: 'İşlemler'},
                        {key: 'lang', label: 'Dil'},
                        {key: 'videoFlag', label: 'Video'},
                        {key: 'mobileFlag', label: 'Mobil'},
                        {key: 'title', label: 'Başlık'},
                        {key: 'text', label: 'Yan Başlık'},
                        {key: 'targetURL', label: 'İçerik Sayfası'}
                    ];
                }
        },
        computed:{
            contents(){
                return this.$store.getters.contents;
            },
            langs: function() {
                let data = ['Dil Seçin']
                this.$store.getters.langs.map(r => data.push({value:r.id, label: r.name}))
                return data
            },
            loading(){
                return this.$store.getters.contentLoading;
            }
        },
        watch:{
            params: function(val){
                this.data = {...val};
                if(this.params.contentMain && this.params.contentMain.contentTypeFolder == ""){
                    this.fields = [
                        {key: 'show_details', label: 'İşlemler'},
                        {key: 'lang', label: 'Dil'},
                        {key: 'mobileFlag', label: 'Mobil'},
                        {key: 'title', label: 'İçerik'},
                        {key: 'targetURL', label: 'İçerik Sayfası'}
                    ];
                } else {
                    this.fields = [
                        {key: 'show_details', label: 'İşlemler'},
                        {key: 'lang', label: 'Dil'},
                        {key: 'videoFlag', label: 'Video'},
                        {key: 'mobileFlag', label: 'Mobil'},
                        {key: 'title', label: 'Başlık'},
                        {key: 'text', label: 'Yan Başlık'},
                        {key: 'targetURL', label: 'İçerik Sayfası'}
                    ];
                }
            },
            actionType:  function(val){
                if(val == 'create'){
                    this.data = {...this.params}
                }
            },

        },
        data: function(){
            return{
                webDomain: process.env.VUE_APP_SERVERINFO_WEBDOMAIN,
                data: this.params ? {...this.params} : {},
                lastItem: {},
                modalDesc: '',
                modalTitle: '',
                modalProps: {},
                openModal: false,
                actionTypeModal: "contentImageForm",
                module: 'content',
                form: "contentImageForm",
                fields: [],
            }
        },
        methods: {
            openModalEvent: async function(actionType, form, data, title, desc){

                if( form == 'contentImageForm'){
                    data.image = {}
                    let params = {
                        contentId: data.content.id
                    }

                    await this.$store.dispatch('content_images', params)
                }

                this.openModal = !this.openModal
                this.actionTypeModal = actionType
                this.form = form
                this.modalProps = data
                this.modalTitle = title
                this.modalDesc = desc
            },
            getContents: async function(){
                let params = {
                    contentMainId: this.data.contentMain.id
                }

                await this.$store.dispatch('content_list', params)
            },
            cancelEdit: function(){
                this.data =  {...this.params}
            },
            editRecord: function(data){
                this.data = {...this.params, ...data}
                this.data.langId = data.lang.id

                for(var i = 0; i <this.contents.length; i++){

                    this.$set(this.contents[i], '_selected', false);
                    this.$set(this.contents[i], '_classes', '');

                }
                
                const val = Boolean(this.contents.find(e => e.id == data.id)._selected);
                this.$set(this.contents.find(e => e.id == data.id), '_classes', !val ? 'selected' : '');
                    
            },
            crud: async function(item){

                var formData = new FormData();

                formData.append('contentId', this.lastItem.id)
                formData.append('actionType', item.image.id ? 'update' : 'create')
                formData.append('fileImage',item.image.fileImage)
                formData.append('fileVideo',item.image.fileVideo)
                formData.append('fileWebp',item.image.fileWebp)

                if(item.image.id)
                 formData.append('id', item.image.id )

                await this.$store.dispatch('content_image_action', formData)

                if(this.$store.getters.contentStatus.success) {
                    let params = {
                        contentId: this.lastItem.id
                    }

                    await this.$store.dispatch('content_images', params)

                    this.modalProps = { content: this.lastItem, image: {} }
                }

            },
            removeRecord: async function(data, actionType){

                let formData = new FormData();
                formData.append('actionType', actionType)
                formData.append('id', data.id)

                await this.$store.dispatch('content_action', formData)

                if(this.$store.getters.contentStatus.success) {

                    let params = {
                        contentMainId: this.data.contentMain.id
                    }

                    await this.$store.dispatch('content_list', params)
                    this.openModalEvent();
                }
            },
            rowClicked(item) {
                this.lastItem = item;
            },
        }
    }
</script>

<style scoped>

    .fieldBox{
        border: 1px solid rgb(204, 204, 204);
        padding: 1rem 1rem 0 1rem;
        margin-bottom: 1rem; 
        position: relative;
    }

    .fieldBoxLabel{
        position: absolute; 
        top: -10px; 
        background: #fff; 
        padding-right: .5rem; 
        padding-left: .5rem;
    }

</style>